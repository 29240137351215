
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import 'ngx-toastr/toastr';

@import url('https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

@import './styles/themes.scss';
@import './styles/global.scss';


$blue: #4281a4;
$dark-blue: #16425b;
// $green: #189E16;
$light-green: #E4EFE4;
$orange: #FF9C27;
$light-orange: #FFF4E8;
$white: #ffffff;

$pijam-primary-palette: (
  50: #dbf3f5,
  100: #a4e2e3,
  200: #5dcfd1,
  300: #00bbbc,
  400: #00abaa,
  500: #009b98,
  600: #008e8a,
  700: #007d78,
  800: #006d68,
  900: #005149,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$pijam-accent-palette: (
  50: #e0f5ff,
  100: #b0e4ff,
  200: #7ad3ff,
  300: #3dc2ff,
  400: #00b4ff,
  500: #00a6fd,
  600: #0098ed,
  700: #0085d9,
  800: #0074c4,
  900: #0054a1,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);




// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pijam-web-app-primary: mat.define-palette($pijam-primary-palette);
$pijam-web-app-accent: mat.define-palette($pijam-accent-palette, 200, 100, 400);

// The warn palette is optional (defaults to red).
$pijam-web-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$pijam-web-app-theme: mat.define-light-theme((
  color: (
    primary: $pijam-web-app-primary,
    accent: $pijam-web-app-accent,
    warn: $pijam-web-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($pijam-web-app-theme);

/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap/dist/css/bootstrap.css";

.cdk-overlay-container {
  z-index: 9999 !important;
}

.custom-btn {
  color: #046690;
  background-color: #fff;
  border-color: #046690;
}

.custom-btn:hover {
  color: #fff;
  background-color: #046690;
  border-color: #046690;
}

.custom-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 102, 144, 0.5);
}
