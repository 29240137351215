[data-theme="theme-brown"] {
    --primary-color: #93751c;
    --secondary-color: #9c6034;
    --lighter-secondary-color: #EAD8C5;
    --text-color: #2E2E2E;
    --background-gradient: linear-gradient(135deg, #FFF8E6 0%, #F1E2C2 50%, #EDE9D6 100%);

    --card-background: #FAEBD7;
    --container-background-color: #fbf7f0;
    --highlight-color: #493628;
    --step-card-background-gradient: linear-gradient(92.72deg, #493628 -6.31%, #815A3E 50%, #C89F7C 104.83%);
}
