[data-theme="theme-light-blue"] {
    --primary-color: #155682;
    --secondary-color: #3181B6;
    --lighter-secondary-color: #E0F4FA;
    --text-color: #ffffff;
    --background-gradient: linear-gradient(135deg, #B8DAE1 0%, #E9F6FC 50%, #B9DAE1 100%);
  
    --card-background: #f0f0f0;
    --container-background-color: white;
    --highlight-color: #FF9C27;
    --step-card-background-gradient: linear-gradient(92.72deg, #092024 -6.31%, #11678A 104.83%);
  }
  