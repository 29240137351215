[data-theme="theme-red"] {
    --primary-color: rgba(237, 60, 26, 1);
    ;
    --secondary-color: rgba(255, 85, 31, 1);
    ;
    --lighter-secondary-color: #E4EFE4;
    --text-color: #ffffff;
    --background-gradient: linear-gradient(180deg, #fef5b8 0%, #fde19d 100%);

    --card-background: #f0f0f0;
    --container-background-color: white;
    --highlight-color: rgba(0, 102, 204, 1);
    --step-card-background-gradient: linear-gradient(92.72deg, #D43F1E -6.31%, #F8A14D 104.83%);
  }
