[data-theme="theme-green"] {
  --primary-color: #175B16;
  --secondary-color: #189E16;
  --lighter-secondary-color: #E4EFE4;
  --text-color: #ffffff;
  --background-gradient: linear-gradient(135deg, #dae1b8 0%, #ecf6e9 50%, #dae1b9 100%);

  --card-background: #f0f0f0;
  --container-background-color: white;
  --highlight-color: #FF9C27;
  --step-card-background-gradient: linear-gradient(92.72deg, #0A2009 -6.31%, #125611 104.83%);

}