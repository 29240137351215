[data-theme="theme-light-pink"] {
    --primary-color: #B25A7A;
    --secondary-color: #D07892;
    --lighter-secondary-color: #FDE4EB;
    --text-color: #ffffff;
    --background-gradient: linear-gradient(135deg, #F9D6E2 0%, #FCE9F1 50%, #F9D6E3 100%);
  
    --card-background: #f8f0f0;
    --container-background-color: white;
    --highlight-color: #FF85B3;
    --step-card-background-gradient: linear-gradient(92.72deg, #702846 -6.31%, #A44A6A 50%, #D07892 104.83%);

  }
  