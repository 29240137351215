[data-theme="theme-blue"] {
    --primary-color: #16425b;
    --secondary-color: #4A7088;
    --lighter-secondary-color: #E4EFE4;
    --text-color: #ffffff;
    --background-gradient: white;
    --highlight-color: #FF9C27;
    //card
    --card-background: #fefefe;
    --container-background-color: #f0f0f0;

    --step-card-background-gradient: linear-gradient(92.72deg, #FE4420 -6.31%, #FFA839 104.83%);
}
